import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
// import HomeView from '../pages/custom/Home.vue'
// import AboutView from '../views/AboutView.vue'
// import TodosView from '../views/TodosView.vue'
//import JobsView from '../views/Jobs'
//import CoursesView from '../views/Courses'
import LoginView from '../pages/base/Login'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/login',
    name: 'login',
    sectionTitle:'Login',
    loggedOut: true,
    component: LoginView
  },
  
  { path: '/profile', name:'profile', title: 'Profiel', component: () => import('../pages/base/User/Profile') },
  { path: '/profile/password', name:'profile_password', title: 'Wachtwoord', component: () => import('../pages/base/User/Password') },

  // TOOLS
  { path: '/tools', icon:'fa-gear',sectionTitle:'Tools', name:'tools_mails', title: 'Mails', component: () => import('../pages/base/Tools/Mails') },
  { path: '/tools/knowledgebase', name:'tools_knowledgebase', title: 'Kennisbank', component: () => import('../pages/custom/Tools/Knowledgebase') },
  { path: '/tools/export', name:'tools_export', title: 'Exporteren', component: () => import('../pages/custom/Tools/Export') },
  { path: '/tools/log', name:'tools_lot', title: 'Log', component: () => import('../pages/base/Tools/Log') },
  { path: '/tools/permissions',   name:'tools_permissions', title:"Permissions", component: () => import('../pages/base/Tools/Permissions') },
  { path: '/tools/database', name:'tools_database', title: 'Database', component: () => import('../pages/base/Tools/Database') },
  { path: '/tools/migrate', name:'tools_migrate', title: 'Migrate', component: () => import('../pages/custom/Tools/Migrate') },
  { path: '/tools/cache', name:'tools_cache', title: 'Cache', component: () => import('../pages/base/Tools/Cache') },
  

  // DASHBOARD
  { path: '/dashboard', sectionTitle:'Dashboard', icon:'fa-gauge', title:'Overzicht', name: 'courses_overview', component: () => import('../pages/custom/Course/List')},
  // { path: '/dashboard/live', title:'Live', name: 'dashboard_live', component: () => import('../pages/custom/Enrollment/List'), meta:{type:'live'} },
  // { path: '/dashboard/elearning', title:'E-learning', name: 'dashboard_elearning', component: () => import('../pages/custom/Enrollment/List'), meta:{type:'online'} },
  // { path: '/dashboard/invoices', title:'Facturen', name: 'dashboard_invoices', component: () => import('../pages/custom/Enrollment/Invoices'), meta:{type:'invoice'} },
  // { path: '/dashboard/certificates', title:'Certificaten', name: 'dashboard_certificates', component: () => import('../pages/custom/Enrollment/Certificates'), meta:{type:'certificate'}  },
  // { path: '/dashboard/mail', title:'Te sturen mail', name: 'dashboard_mail', component: () => import('../pages/custom/Course/Todo'), meta:{type:'mail'} },
  // { path: '/dashboard/order', title:'Materiaal bestellen', name: 'dashboard_order', component: () => import('../pages/custom/Course/Todo'), meta:{type:'order'} },
  { path: '/dashboard/history', title:'Historie', name: 'dashboard_history', props: (route) => ({ urlSearchTerm: route.query.urlSearchTerm }), component: () => import('../pages/custom/Enrollment/History')},
  { path: '/dashboard/short', title:'Beknopt', name: 'dashboard_short', component: () => import('../pages/custom/Course/Short')},
  { path: '/dashboard/debtors', title:'Debiteuren', name: 'invoices_debtors', component: () => import('../pages/custom/Enrollment/Debtors')},
  { path: '/dashboard/newelearning', title:'Inschrijven E-Learning', name: 'signup_elearning', component: () => import('../pages/custom/Enrollment/NewELearning')},
  
  
  // TODO
  //{ path: '/actions', sectionTitle:'Acties', icon:'fa-ballot-check', title:'Acties', name: 'courses_overview', component: () => import('../pages/custom/Course/List')},
  { path: '/actions', sectionTitle:'Acties', icon:'fa-ballot-check', title:'Live', name: 'dashboard_live', component: () => import('../pages/custom/Enrollment/List'), meta:{type:'live'} },
  { path: '/actions/elearning', title:'E-learning', name: 'dashboard_elearning', component: () => import('../pages/custom/Enrollment/List'), meta:{type:'online'} },
  { path: '/actions/invoices', title:'Facturen', name: 'dashboard_invoices', component: () => import('../pages/custom/Enrollment/Invoices'), meta:{type:'invoice'} },
  { path: '/actions/certificates', title:'Certificaten', name: 'dashboard_certificates', component: () => import('../pages/custom/Enrollment/Certificates'), meta:{type:'certificate'}  },
  { path: '/actions/mail', title:'Te sturen mail', name: 'dashboard_mail', component: () => import('../pages/custom/Course/Todo'), meta:{type:'mail'} },
  { path: '/actions/order', title:'Materiaal bestellen', name: 'dashboard_order', component: () => import('../pages/custom/Course/Todo'), meta:{type:'order'} },
  

  // UPCOMING
  { path: '/upcoming', sectionTitle:'Planning', icon:'fa-calendar-alt', title:'Overzicht', name: 'courses_upcoming', component: () => import('../pages/custom/Course/Upcoming')},

  //{ path: '/enrollments/edit/:id', name: 'enrollments_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Inschrijving bewerken', controller:'enrollments', afterSave:{all:'/dashboard'}} },

  // CALENDAR
  { path: '/calendar', sectionTitle: 'Kalender', icon:'fa-calendar-alt', name:'calendar',  title:"Overzicht", component: () => import('../pages/custom/Calendar/Overview') },


  // BASECOURSES
  { path: '/basecourses', sectionTitle:'Cursussen', icon:'fa-graduation-cap', title:'Overzicht', name: 'basecourses_overview', component: () => import('../pages/custom/BaseCourse/List') },
  { path: '/basecourses/edit/:id', name: 'basecourses_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Basiscursus bewerken', controller:'basecourses', afterSave:{all:'/basecourses'}} },
  { path: '/basecourses/details/:id', name: 'basecourses_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Basiscursus details', controller:'basecourses'} },
  { path: '/basecourses/newcourse/:id', name: 'basecourses_newcourse', component: () => import('../pages/custom/Course/New'), meta:{title:'Nieuwe cursus plannen', controller:'basecourses', incompany:false} },
  { path: '/basecourses/newincompanycourse/:id', name: 'basecourses_newincompanycourse', component: () => import('../pages/custom/Course/New'), meta:{title:'Nieuwe cursus plannen', controller:'basecourses', incompany:true} },
  { path: '/basecourses/new', name: 'basecourses_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Basiscursus toevoegen', controller:'basecourses', afterSave:{all:'/basecourses'}} },
  { path: '/basecourses/planned', title:'Planning', name: 'courses_planned', component: () => import('../pages/custom/Course/Planned'), meta:{title:'Geplande cursussen', controller:'courses'} },
  { path: '/basecourses/basecourseactions', title:'Cursus acties', name: 'basecoursesactions', component: () => import('../pages/custom/BaseCourse/BaseCourseActions'), meta:{title:'Basiscursus acties', controller:'basecourseactions'} },
  //{ path: '/basecourses/basecourseactions/edit/:id', name: 'basecourseactions_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Cursusactie bewerken', controller:'basecourseactions', afterSave:{all:'/basecourses'}} },
  { path: '/basecourses/basecourseactions/:basecourseid', alias:'/basecourses/basecourseactions', name: 'basecoursesactions_course', component: () => import('../pages/custom/BaseCourse/BaseCourseActions'), meta:{title:'Basiscursus acties', controller:'basecourseactions'} },
  { path: '/basecourses/basecourseactions/:basecourseid/new', name: 'basecourseactions_new', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Cursus actie aanmaken', controller:'basecourseactions',
      afterSave:{
        update:'/basecourses/basecourseactions/:basecourseid'
      }} },

  { path: '/basecourses/mailtemplates_default', title:'Standaard mails', name: 'basecourses_mailtemplates_default', component: () => import('../pages/custom/BaseCourse/Mailtemplates'), meta:{title:'Basiscursus mailtemplates', controller:'mailtemplates'} },
  { path: '/basecourses/mailtemplates_course', title:'Cursus mails', name: 'basecourses_mailtemplates_course', component: () => import('../pages/custom/BaseCourse/Mailtemplates'), meta:{title:'Basiscursus mailtemplates', controller:'mailtemplates'} },
  { path: '/basecourses/mailtemplates_course/:basecourseid', alias:'/basecourses/mailtemplates_course', name: 'basecourses_mailtemplates_course', component: () => import('../pages/custom/BaseCourse/Mailtemplates'), meta:{title:'Basiscursus mailtemplates', controller:'mailtemplates'} },
  { path: '/basecourses/printorderpdf/:basecourseid', name: 'basecourses_printordertext', component: () => import('../pages/custom/BaseCourse/PrintorderPDF'), meta:{title:'Printorder PDF', controller:'basecourses'} },
  { path: '/basecourses/mailtemplates_variables', title:'Mail variabelen', name: 'basecourses_mailtemplates_variables', component: () => import('../pages/custom/BaseCourse/MailtemplateVariables'), meta:{title:'Mailtemplate variabelen', controller:'mailtemplates'} },
  { path: '/basecourses/mailtemplates_blocks', title:'Mail blokken', name: 'basecourses_mailtemplates_blocks', component: () => import('../pages/custom/BaseCourse/MailtemplateBlocks'), meta:{title:'Mailtemplate blokken', controller:'mailtemplateblocks'} },
  { path: '/basecourses/mailtemplateblocks/details/:id', name: 'basecourses_mailtemplates_blocks_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Basiscursus details', controller:'basecourses'} },
  { path: '/basecourses/downloads', title:'Downloads', name: 'basecourses_downloads', component: () => import('../pages/custom/Library/Downloads'), meta:{title:'Downloads', controller:'downloads'} },
  { path: '/basecourses/downloads/new', name: 'downloads_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Download toevoegen', controller:'downloads', afterSave:{all:'/basecourses/downloads'}} },
  { path: '/basecourses/mailtemplates_course/:bc_id/new', name: 'mailtemplates_course_new', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Mailtemplate aanmaken', controller:'mailtemplates',
      afterSave:{
        update:'/basecourses/mailtemplates_course/:bc_id'
      }} },
  { path: '/basecourses/mailtemplates_default/edit/:id', name: 'mailtemplates_default_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Mailtemplate bewerken', controller:'mailtemplates',
      afterSave:{
        update:'/basecourses/mailtemplates_default'
      }} },
  { path: '/basecourses/mailtemplates_course/edit/:id', name: 'mailtemplates_course_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Mailtemplate bewerken', controller:'mailtemplates',
      afterSave:{
        update:'/basecourses/mailtemplates_course'
      }} },
  
  // CURSISTEN
  { path: '/students', sectionTitle:'Cursisten', icon:'fa-user-graduate',  title:'Overzicht', name: 'participants_overview', component: () => import('../pages/custom/Participant/List')},
  { path: '/students/edit/:id', name: 'participants_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Cursist bewerken', controller:'participants', afterSave:{all:'/participants'}} },
  { path: '/students/details/:id', name: 'participants_details', component: () => import('../pages/custom/Participant/Details'), meta:{title:'Cursist details', controller:'participants'} },
  { path: '/students/new', name: 'participants_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Cursist toevoegen', controller:'participants', afterSave:{all:'/participants'}} },


// KLANTEN
{ path: '/customers', sectionTitle:'Klanten', icon:'fa-people-group',  title:'Overzicht', name: 'customers_overview', component: () => import('../pages/custom/Customer/List') },
// { path: '/customers/edit/:id', name: 'customer_edit', component: () => import('../pages/custom/Customer/Edit') },
// { path: '/customers/new', name: 'customer_add', component: () => import('../pages/custom/Customer/Edit') },
{ path: '/customers/edit/:id', name: 'customers_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Klant bewerken', controller:'customers', afterSave:{all:'/customers'}} },
{ path: '/customers/details/:id', name: 'customers_details', component: () => import('../pages/custom/Customer/Details'), meta:{title:'Klant details', controller:'customers'} },
{ path: '/customers/addstudent/:id', name: 'customers_addparticipant', component: () => import('../pages/custom/Customer/AddParticipant'), meta:{title:'Klant details', controller:'customers'} },
{ path: '/participants/details/:id', name: 'customer_participants_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Cursist details', controller:'participants'} },
{ path: '/customers/new', name: 'customers_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Klant toevoegen', controller:'customers', afterSave:{all:'/customers'}} },



// MEDEWERKERS
{ path: '/users', sectionTitle:'Gebruikers', icon:'fa-person-chalkboard', title:'Overzicht',name: 'users_overview', component: () => import('../pages/custom/User/List') },
// { path: '/users/edit/:id', name: 'user_edit', component: () => import('../pages/base/User/Edit') },
// { path: '/users/new', name: 'user_add', component: () => import('../pages/base/User/Edit') },
{ path: '/users/details/:id', name: 'users_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Gebruiker details', controller:'users'} },
{ path: '/users/edit/:id', name: 'users_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker bewerken', controller:'users', afterSave:{all:'/users'}} },
{ path: '/users/new',  name: 'users_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Gebruiker toevoegen', controller:'users', afterSave:{all:'/users'}} },

// roles
{ path: '/users/roles', sectionTitle:'Rollen', title:'Rollen', name: 'roles_overview', component: () => import('../pages/base/Role/List') },
{ path: '/roles/edit/:id', name: 'roles_edit', component: () => import('../pages/base/Role/Edit') },
{ path: '/roles/new', title:'Toevoegen', name: 'roles_add', component: () => import('../pages/base/Role/Edit') },

// LOCATIES
{ path: '/courselocations', sectionTitle:'Locaties', icon:'fa-location-dot', title:'Overzicht', name: 'courselocations_overview', component: () => import('../pages/custom/CourseLocation/List') },
// { path: '/courselocations', sectionTitle:'Cursus lokaties', title:'Overzicht', name: 'courselocations_overview', component: () => import('../pages/base/_Entity/List'), meta:{title:'Cursuslokatie overzicht', controller:'courselocations', edit:'courselocations_edit', add:'courselocations_add', tableConfig:{
//   columns:[
//     {field:'id', title:'ID'},
//     {field:'name', title:'Naam'},
//     {field:'street', title:'Straat'},
//     {field:'streetnumber', title:'Nummer'},
//     {field:'postal', title:'Postcode'},
//     {field:'city', title:'Stad'}
//   ]
// }} },
// { path: '/courselocations/edit/:id', name: 'courselocation_edit', component: () => import('../pages/custom/CourseLocation/Edit') },
// { path: '/courselocations/new', name: 'courselocation_add', component: () => import('../pages/custom/CourseLocation/Edit') },
{ path: '/courselocations/edit/:id', name: 'courselocations_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Cursuslocatie bewerken', controller:'courselocations', afterSave:{all:'/courselocations'}} },
{ path: '/courselocations/details/:id', name: 'courselocations_details', component: () => import('../pages/base/_Entity/Details'), meta:{title:'Cursuslocatie details', controller:'courselocations'} },
{ path: '/courselocations/new', name: 'courselocations_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Cursuslocatie toevoegen', controller:'courselocations', afterSave:{all:'/courselocations'}} },


// LIBRARY
{ path: '/library', sectionTitle: 'Bibliotheek', icon:'fa-photo-film', className:'hasFileTree', name:'library', title:"Overzicht", component: () => import('../pages/custom/Library/Overview') },

// SETTINGS
{ path: '/settings', sectionTitle: 'Instellingen', icon:'fa-gears', name:'settings', title:"Variabelen", component: () => import('../pages/base/Settings/Overview') },
{ path: '/settings/mailstatus', name:'settings_mailstatus', title:"Mail status", component: () => import('../pages/base/Settings/Mailstatus') },
{ path: '/settings/msgraph', name:'settings_msgraph', title:"Microsoft", component: () => import('../pages/custom/Settings/MSGraph') },


 
  

  // { path: '/basecourses', sectionTitle:'Basiscursussen', title:'Overzicht', name: 'basecourses', component: () => import('../pages/base/_Entity/List'),
  //   meta:{
  //     title:'Basiscursus overzicht',
  //     controller:'basecourses',
  //     edit:'basecourse_edit',
  //     add:'basecourse_add',
  //     tableConfig:{
  //       columns:[
  //         {field:'id', title:'ID'},
  //         {field:'name', title:'Naam'},
  //         {field:'price', title:'Prijs'},
  //         {field:'online', title:'Online?'},
  //         {field:'acc_no', title:'Acc. nummer'}
  //       ]
  //     }
  //   }
  // },

  
  //{ path: '/courses', sectionTitle:'Cursussen', title:'Overzicht', name: 'courses_overview', component: () => import('../pages/custom/Course/List') },
  // { path: '/courses', sectionTitle:'Cursussen', title:'Overzicht', name: 'courses', component: () => import('../pages/base/_Entity/List'),
  //   meta:{
  //     title:'Cursus overzicht',
  //     controller:'courses',
  //     edit:'course_edit',
  //     add:'course_add',
  //     actionsComponent:() => import('../components/custom/actions/Course'),
  //     tableConfig:{
  //       columns:[
  //         {field:'id', title:'ID'},
  //         {field:'BaseCourse.name', title:'Cursus'},
  //         {field:'name', title:'Naam'},
  //         {field:'CourseDates[0].date', title:'Startdatum'},
  //         //{subpage:'courses_coursedates', title:'Data'},
  //       ]
  //     }
  //   }
  // },
  // { path: '/courses/:id/coursedates', title:'Cursusdata', name: 'courses_coursedates', component: () => import('../pages/base/_Entity/List'),
  //   meta:{
  //     title:'Cursusdata overzicht',
  //     controller:'courses',
  //     edit:'course_edit',
  //     add:'course_add',
  //     tableConfig:{
  //       filter:'coursedates',
  //       columns:[
  //         {field:'id', title:'ID'},
  //         {field:'date', title:'Datum'},
  //         {field:'CourseLocation.name', title:'Lokatie'},
  //         {field:'notes', title:'Notities'}
  //       ]
  //     }
  //   }
  // },  
  
  { path: '/courses', sectionTitle:'Cursussen', name: 'courses_list', component: () => import('../pages/custom/Course/List') },
  //{ path: '/courses/edit/:id', name: 'course_edit', component: () => import('../pages/custom/Course/Edit') },
  //{ path: '/courses/new', name: 'course_add', component: () => import('../pages/custom/Course/Edit') },
  { path: '/courses/edit/:id', name: 'courses_edit', component: () => import('../pages/custom/Course/Edit'), meta:{title:'Cursus bewerken', controller:'courses', afterSave:{all:'/courses'}} },
  //{ path: '/courses/edit/:id', name: 'courses_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Basiscursus bewerken', controller:'basecourses', afterSave:{all:'/basecourses'}} },

  { path: '/courses/details/:id', name: 'courses_details', component: () => import('../pages/custom/Course/Details'), meta:{title:'Cursus details', controller:'courses'} },
  //{ path: '/courses/new', title:'Toevoegen', name: 'courses_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Cursus toevoegen', controller:'courses', afterSave:{all:'/courses'}} },
  
  
  //{ path: '/coursedates', name: 'coursedates', component: () => import('../pages/custom/CourseDate/List') },
  { path: '/coursedates', sectionTitle:'Cursusdata', title:'Overzicht', name: 'coursedates_overview', component: () => import('../pages/base/_Entity/List'), meta:{title:'Cursusdatum overzicht', controller:'coursedates', edit:'coursedates_edit', add:'coursedates_add', tableConfig:{
    columns:[
      {field:'id', title:'ID'},
      {field:'date', title:'Datum'},
      {field:'Course.name', title:'Cursus'},
      {field:'CourseLocation.name', title:'Locatie'},
      {field:'notes', title:'Notities'}
    ]
  }} },
  // { path: '/coursedates/edit/:id', name: 'coursedate_edit', component: () => import('../pages/custom/CourseDate/Edit') },
  // { path: '/coursedates/new', name: 'coursedate_add', component: () => import('../pages/custom/CourseDate/Edit') },
  { path: '/coursedates/edit/:id', name: 'coursedates_edit', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Cursusdatum bewerken', controller:'coursedates', afterSave:{all:'/coursedates'}} },
  { path: '/coursedates/new', title:'Toevoegen', name: 'coursedates_add', component: () => import('../pages/base/_Entity/Edit'), meta:{title:'Cursusdatum toevoegen', controller:'coursedates', afterSave:{all:'/coursedates'}} },
  
  // { path: '/customers', sectionTitle:'Klanten', title:'Overzicht', name: 'customers', component: () => import('../pages/base/_Entity/List'), meta:{title:'Klanten overzicht', controller:'customers', edit:'customer_edit', add:'customer_add', tableConfig:{
    //   columns:[
      //     {field:'id', title:'ID'},
      //     {field:'name', title:'Naam'},
      //     {field:'address', title:'Adres'},
      //     {field:'postal', title:'Postcode'},
      //     {field:'city', title:'Stad'},
      //     {field:'Country.name', title:'Land'}
      //   ]
      // }} },





  // users

  //{ path: '/inschrijven', sectionTitle: 'Inschrijven', title:"Nieuw", component: () => import('../pages/custom/Enrollment/Create') },

  //{ path: '/about', name: 'about', component: AboutView }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  document.title = "Van Oss & Partners admin";
  next();
});

export default router
